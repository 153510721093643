.App {
  height: 100vh;
  text-align: center;
  background-color: #000;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  vertical-align: middle;
}

.Quote {
  font-size: 2em;
  /* center */
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 600;
  /* relative to bottom */
  color: #126c91;
}

/* twinkling stars animated background */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Path: src\index.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* button shown on hover */

.hide {
  display: none;
}

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  font-size: 8px;
  margin: 2px 2px;
  cursor: pointer;
  bottom: 0;
  right: 0;
  position: absolute;
  display: inline-block;
}

.button:hover {
  background-color: #3e8e41;
  visibility: visible;
}
.button:hover + .hide {
  background-color: #3e8e41;
  visibility: visible;
  display: inline-block;
}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(2px);
  visibility: visible;
  display: inline-block;
}
